import { useMemo } from 'react';
import { Query_Root, Sleep_Diary } from '@global/utils/remote-graphql-types';
import { getSleepDiaryAnalysis, SleepDiaryParams } from '@global/utils/domain/diary-analysis';

export interface SleepDataSummaryItem {
  startDate: string;
  endDate: string;
  minutesInBed: number;
  minutesSleep: number;
  eficiency: number;
}

export interface SleepRestrictionSummary {
  currentCycleDiaries: Sleep_Diary[];

  everythingSummary: SleepDataSummaryItem;
  currentCycleSummary: SleepDataSummaryItem;
  lastCycleSummary?: SleepDataSummaryItem;
}

export const compareSleepDataSummaryItem = (a1: SleepDataSummaryItem, a2: SleepDataSummaryItem) => {
  if (!a1 || !a2) {
    return {};
  }

  return {
    minutesInBed: (((a1.minutesInBed - a2.minutesInBed) / a2.minutesInBed) * 100).toFixed(1),
    minutesSleep: (((a1.minutesSleep - a2.minutesSleep) / a2.minutesSleep) * 100).toFixed(1),
    eficiency: (((a1.eficiency - a2.eficiency) / a2.eficiency) * 100).toFixed(1),
  };
};

export const useSleepRestrictionSummary = (data: Query_Root) => {
  return useMemo(() => {
    if (!data || !data.sleep_diary || !data.sleep_restriction || !data.sleep_restriction[0]) {
      return {} as SleepRestrictionSummary;
    }

    const everythingSummary = getCycleSummary(data.sleep_diary);

    const currentCycleStartDate = new Date(data.sleep_restriction[0].start_date);
    const currentCycleData = data.sleep_diary.filter((entry) => new Date(entry.date) > currentCycleStartDate);
    const currentCycleSummary = getCycleSummary(currentCycleData);

    const response: SleepRestrictionSummary = {
      everythingSummary,
      currentCycleSummary,
      currentCycleDiaries: currentCycleData,
    };

    if (data.sleep_restriction && data.sleep_restriction[1]) {
      const lastCycleStartDate = new Date(data.sleep_restriction[1].start_date);
      const lastCycleData = data.sleep_diary.filter((entry) => {
        const entryDate = new Date(entry.date);
        return lastCycleStartDate < entryDate && entryDate < currentCycleStartDate;
      });
      const lastCycleSummary = getCycleSummary(lastCycleData);
      response.lastCycleSummary = lastCycleSummary;
    }
    return response;
  }, [data]);
};

function getCycleSummary(sleepDiary: Sleep_Diary[]): SleepDataSummaryItem {
  if (!sleepDiary || sleepDiary.length === 0) {
    return {
      minutesInBed: null,
      minutesSleep: null,
      eficiency: null,
      endDate: null,
      startDate: null,
    };
  }

  const avgList = sleepDiary.map((entry) => {
    const sleepDiaryParams: SleepDiaryParams = {
      goBed: entry.go_bed,
      goSleep: entry.go_sleep,
      timeToSleep: entry.time_to_sleep,
      wakeUpDuration: entry.wake_up_duration,
      wakeUp: entry.wake_up,
      getUp: entry.get_up,
    };
    return getSleepDiaryAnalysis(sleepDiaryParams);
  });
  const avg = avgList.reduce(
    (acc, curr) => {
      acc.minutesInBed += curr.minutesInBed;
      acc.minutesSleep += curr.minutesSleep;
      acc.eficiency += curr.eficiency;
      return acc;
    },
    { minutesInBed: 0, minutesSleep: 0, eficiency: 0 }
  );

  avg.minutesInBed /= avgList.length;
  avg.minutesSleep /= avgList.length;
  avg.eficiency /= avgList.length;

  return {
    ...avg,
    startDate: sleepDiary[0].date,
    endDate: sleepDiary[sleepDiary.length - 1].date,
  };
}
