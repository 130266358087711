import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { toHour } from '@global/utils/date/to-hour';
import { BodySmall, Col, Row } from '@web/atomic';
import { Table, TD, TH, THead, TR } from '@web/atomic/legacy/mol.table';
import * as React from 'react';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { EfficiencyColorStyled } from '@web/atomic/atm.opinionated-color/opinionated-color.styled';
import { SleepRestrictionSummary, SleepDataSummaryItem } from './sleep-restriction-summary-table.utils';

interface ISleepRestrictionSummaryTableProps {
  summary: SleepRestrictionSummary;
}

export const SleepRestrictionSummaryTable: React.FunctionComponent<ISleepRestrictionSummaryTableProps> = (props) => {
  const summary = props.summary;
  if (!(summary && summary.currentCycleSummary)) {
    return null;
  }
  return (
    <Row mb>
      <Col xs={12}>
        <Table>
          <THead>
            <TR>
              <TH textAlign="left">Ciclo</TH>
              <TH textAlign="left">Tempo na cama</TH>
              <TH textAlign="left">Tempo de sono</TH>
              <TH textAlign="right">Eficiência</TH>
            </TR>
          </THead>
          {summary.currentCycleSummary && <SleepRestrictionCycleTableRow title={'Atual'} summary={summary.currentCycleSummary} />}
          {summary.lastCycleSummary && <SleepRestrictionCycleTableRow title={'Anterior'} summary={summary.lastCycleSummary} />}
          <SleepRestrictionCycleTableRow title={'Todos'} summary={summary.everythingSummary} />
        </Table>
      </Col>
    </Row>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ISleepRestrictionCycleTableRowProps {
  summary: SleepDataSummaryItem;
  title: string;
}

const SleepRestrictionCycleTableRow: React.FunctionComponent<ISleepRestrictionCycleTableRowProps> = (props) => {
  if ([props.title, props.summary.minutesInBed, props.summary.minutesSleep, props.summary.eficiency].some(isNullOrUndefined)) {
    return null;
  }
  return (
    <TR>
      <TD textAlign="left" minWidth={'92px'}>
        {props.title} <br />
        <BodySmall>
          <strong>
            ({parseDateToShortString(parseToDate(props.summary.startDate))} - {parseDateToShortString(parseToDate(props.summary.endDate))})
          </strong>
        </BodySmall>
      </TD>
      <TD textAlign="left" minWidth={'80px'}>
        {toHour(props.summary.minutesInBed)}
      </TD>
      <TD textAlign="left" minWidth={'80px'}>
        {toHour(props.summary.minutesSleep)}
      </TD>
      <TD textAlign="left">
        <EfficiencyColorStyled value={props.summary.eficiency}>{props.summary.eficiency.toFixed(2)}%</EfficiencyColorStyled>
      </TD>
    </TR>
  );
};
