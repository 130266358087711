import { GrayColor, LightColor, FontFamily, FontSize, FontWeight, Spacing } from '@web/atomic/legacy/obj.constants';
import styled from 'styled-components';
import { SegmentedKind, SegmentedProps } from './segmented.component';

export const SegmentedStyled = styled.span`
  display: block;
  position: relative;
  & ~ * {
    margin-top: ${Spacing.Medium};
  }
`;

export const SegmentedItemStyled = styled.button<{ selected?: boolean; kind: SegmentedKind }>`
  background: none;
  position: relative;
  color: ${(props) => (props.kind === 'light' ? GrayColor.White : props.selected ? LightColor.Primary : GrayColor.GrayDark)};
  border-color: ${GrayColor.Gray};
  border-style: solid;
  border-right-width: 0;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 1px;
  width: auto;
  min-height: 32px;
  padding: ${Spacing.Medium} ${Spacing.Small} ${'12px'} ${Spacing.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${(props) => (props.selected ? FontWeight.Bold : FontWeight.Normal)};
  font-size: ${FontSize.XSmall};

  cursor: pointer;

  ::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: -2px;
    left: 0;
    opacity: ${(props: { selected?: boolean }) => (props.selected ? 1 : 0)};
    border-color: ${(props: { kind: SegmentedKind }) => (props.kind === 'light' ? GrayColor.White : LightColor.Primary)};
    border-style: solid;
    border-right-width: 0;
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-width: 3px;
    ${(props: SegmentedProps) => (props.animated ? `transition: all 0.3s ease-in-out 0.1s` : '')};
  }

  &:focus {
    outline: 0;
  }
`;
