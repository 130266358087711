import { DayGoalGraph } from '@component/charts/day-goal-graph.component';
import { GradeGraph } from '@component/charts/grade-graph.component';
import { NightGoalGraph } from '@component/charts/night-goal-graph.component';
import { SleepDiaryGraph } from '@component/charts/sleep-diary-graph.component';
import { getDiariesWithEmptyValues, SleepDiaryGraphProps } from '@component/charts/utils';
import { GatsbyButton } from '@components/atm.button/button.component';
import { getTitleForDayGoal, getTitleForNightGoal, NightGoals } from '@global/utils/domain/goals';
import { Query_Root } from '@global/utils/remote-graphql-types';
import { SleepDiaryTable } from '@lp-root/src/components/mol.sleep-diary-table/sleep-diary-table.component';
import { SleepRestrictionSummaryTable } from '@lp-root/src/components/mol.sleep-restriction-summary-table/sleep-restriction-summary-table.component';
import { useSleepRestrictionSummary } from '@lp-root/src/components/mol.sleep-restriction-summary-table/sleep-restriction-summary-table.utils';
import { SeeSleepDiaryQuery } from '@lp-root/src/data/graphql/hasura/see-diary.query.hasura';
import { AppThemeContext, BodySecondary, Col, FaIcon, Grid, H1, H2, Row } from '@web/atomic';
import { Segmented } from '@web/atomic/legacy/atm.segmented';
import { FetchDataErrorPlaceholder, Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { TextShimmerBoxStyled, ThumbShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useClientRect } from '@web/atomic/obj.custom-hooks/client-rect.hook';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React, { useCallback, useContext, useState } from 'react';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { PageProps } from '../../utils/local-types';

enum VerDiarioTabs {
  graph = 0,
  table = 1,
}

const VerDiarioPage: React.FunctionComponent<PageProps> = (props) => {
  const { data, error, loading } = useQueryCustom(SeeSleepDiaryQuery);
  const diaries = getDiariesWithEmptyValues(data && data.sleep_diary);

  // TODO: refactor - criar componente para lista e componetne paragraph
  const [selectedTab, setSelectedTab] = useState(VerDiarioTabs.graph);
  const handleSelectTab = useCallback(
    (selection) => {
      setSelectedTab(selection);
    },
    [setSelectedTab]
  );

  const [rect, titleRef] = useClientRect();
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();

  const { theme } = useContext(AppThemeContext);

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Diário do sono',
          description: '',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12}>
            <H1 ref={titleRef}>Diário do sono</H1>
          </Col>
        </Row>
        <LoadingState loading={loading} error={!!error} data={!!diaries}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <>
              <Row mt mb center={'xs'}>
                <Col>
                  <ThumbShimmerBoxStyled width={'150px'} height={'48px'} />
                </Col>
              </Row>
              <Row mt mb>
                <Col xs={12}>
                  <TextShimmerBoxStyled height={'22px'} />
                </Col>
              </Row>
            </>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <FetchDataErrorPlaceholder error={error} />
          </LoadingState.Error>
          <LoadingState.NoData>
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Seu diário está em branco'}
              description={`Quando você preencher seu diário, os seus dados podem ser vistos nessa tela, ok? Preencha 1 vez e veja como fica aqui!`}
            >
              <GatsbyButton onClick={close} loading={waitCloseLoading}>
                Combinado!
              </GatsbyButton>
            </Placeholder>
          </LoadingState.NoData>
          <Row mt mb center={'xs'}>
            <Col>
              <Segmented kind={theme.name === 'dark' ? 'light' : 'default'} onChange={handleSelectTab}>
                <Segmented.Item>Gráfico</Segmented.Item>
                <Segmented.Item>Tabela</Segmented.Item>
              </Segmented>
            </Col>
          </Row>
          <Row mt mb>
            <Col xs={12}>
              {selectedTab === VerDiarioTabs.graph && (
                <GraphTab width={rect && rect.width} sleepDiaryData={diaries} rawData={data} user={data && data.user[0]} />
              )}
              {selectedTab === VerDiarioTabs.table && <SleepDiaryTable data={data && data.sleep_diary} />}
            </Col>
          </Row>
        </LoadingState>
      </Grid>
    </WebviewLayout>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface GraphTabProps extends SleepDiaryGraphProps {
  rawData: Query_Root;
}

const GraphTab: React.FunctionComponent<GraphTabProps> = (props) => {
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const dataLengthToShow = 3;

  const summary = useSleepRestrictionSummary(props.rawData);

  if (!props.sleepDiaryData || props.sleepDiaryData.length < dataLengthToShow) {
    const daysLeft = dataLengthToShow - (props.sleepDiaryData ? props.sleepDiaryData.length : 0);
    return (
      <Placeholder
        icon={<FaIcon.ChartArea size="9x" />}
        title={'Gráfico quase disponível'}
        description={`Preencha seu diário do sono mais ${daysLeft} dia${
          daysLeft === 1 ? '' : 's'
        } e você ganhará acesso a essa funcionalidade. 😉`}
      >
        <GatsbyButton onClick={close} loading={waitCloseLoading}>
          Combinado!
        </GatsbyButton>
      </Placeholder>
    );
  }

  return (
    <>
      <H2>Tempo de cama, tempo de sono e eficiência</H2>
      <SleepRestrictionSummaryTable summary={summary} />
      <SleepDiaryGraph width={props.width} sleepDiaryData={props.sleepDiaryData} user={props.user} />

      {props.user.night_goal !== NightGoals.Mood && (
        <>
          <H2>Como você acordou</H2>
          <GradeGraph width={props.width} sleepDiaryData={props.sleepDiaryData} user={props.user} />
        </>
      )}
      {props.user.day_goal && (
        <>
          <H2>Objetivo do dia</H2>
          <BodySecondary>{getTitleForDayGoal(props.user.day_goal)}</BodySecondary>
          <DayGoalGraph width={props.width} sleepDiaryData={props.sleepDiaryData} user={props.user} />
        </>
      )}
      {props.user.night_goal && (
        <>
          <H2>Objetivo da noite</H2>
          <BodySecondary>{getTitleForNightGoal(props.user.night_goal)}</BodySecondary>
          <NightGoalGraph width={props.width} sleepDiaryData={props.sleepDiaryData} user={props.user} />
        </>
      )}
    </>
  );
};

export default VerDiarioPage;

export const query = graphql`
  query VerDiarioQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
